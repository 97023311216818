/* istanbul ignore file */

import {
  FeatureFlags,
  FEATURE_FLAGS_ID_ATTRIBUTE
} from '@atlassiansox/bxp-content-framework';

const element = document.getElementById(FEATURE_FLAGS_ID_ATTRIBUTE);
if (!element) {
  throw new Error(`${FEATURE_FLAGS_ID_ATTRIBUTE} not found.`);
}

export const featureFlags = JSON.parse(element.innerHTML) as FeatureFlags;
