/* istanbul ignore file */

import {
  ConfigProvider,
  FeatureFlagsProvider
} from '@atlassiansox/bxp-content-framework';
import {
  App,
  APP_ID,
  TrelloClientProvider,
  TrelloClient,
  CookieLanguageProvider
} from '@atlassiansox/bxp-trello-blueprint';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { config } from './config';
import { featureFlags } from './feature-flags';

Sentry.init({
  dsn: 'https://04d440e023fa432b85745f7b201ff84d@o55978.ingest.sentry.io/5988955',
  environment: config.environment,
  release: config.version
});

// stub out process.cwd() for react-markdown
// @ts-expect-error
window.process = { cwd: () => '' };

loadableReady(async () => {
  const container = document.getElementById(APP_ID);
  if (container) {
    const baseURL =
      document.getElementById('BXP_BASE_URL')?.getAttribute('href') ?? '';
    const json = document.getElementById('BXP_PAGE_MODEL')?.innerHTML;

    if (json) {
      ReactDOM.hydrate(
        <Sentry.ErrorBoundary
          fallback={
            <p>
              An error has occurred. <a href="/"> Go home</a>.
            </p>
          }
          beforeCapture={(scope) => {
            scope.setTag('team', 'arcadia');
          }}
        >
          <FeatureFlagsProvider featureFlags={featureFlags}>
            <ConfigProvider config={config}>
              <CookieLanguageProvider>
                <BrowserRouter basename={baseURL}>
                  <TrelloClientProvider
                    client={
                      new TrelloClient({
                        baseUrl: config.trelloClientBaseUrl
                      })
                    }
                  >
                    <App defaultPageModel={JSON.parse(json)} />
                  </TrelloClientProvider>
                </BrowserRouter>
              </CookieLanguageProvider>
            </ConfigProvider>
          </FeatureFlagsProvider>
        </Sentry.ErrorBoundary>,
        container
      );
    } else {
      throw new Error('Could not find page model.');
    }
  } else {
    throw new Error(`Could not find container with id: ${APP_ID}`);
  }
}).catch(console.error);
