/* istanbul ignore file */

export const publicPath = `${
  document.getElementById('BXP_CONTENT_STATIC_BASE_URL')?.innerHTML ?? ''
}/`;

window.__webpack_public_path__ = publicPath;

declare global {
  interface Window {
    __webpack_public_path__: string;
  }
}
