/* istanbul ignore file */

import {
  Config,
  CONFIG_ID_ATTRIBUTE
} from '@atlassiansox/bxp-content-framework';

const element = document.getElementById(CONFIG_ID_ATTRIBUTE);
if (!element) {
  throw new Error(`${CONFIG_ID_ATTRIBUTE} not found.`);
}

export const config = JSON.parse(element.innerHTML) as Config;
